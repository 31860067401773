import * as React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import objectFitImages from 'object-fit-images';
import 'focus-visible/dist/focus-visible.js';

export const wrapRootElement = ({ element }) => (
    <PreviewStoreProvider>{element}</PreviewStoreProvider>
)

objectFitImages();

async function loadPolyfills() {
    if (typeof window.IntersectionObserver === 'undefined') {
        await require('intersection-observer');
    }
}

loadPolyfills();
