// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-new-gen-application-js": () => import("./../../../src/pages/new-gen-application.js" /* webpackChunkName: "component---src-pages-new-gen-application-js" */),
  "component---src-pages-rpost-js": () => import("./../../../src/pages/rpost.js" /* webpackChunkName: "component---src-pages-rpost-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-index-category-js": () => import("./../../../src/templates/blog-index-category.js" /* webpackChunkName: "component---src-templates-blog-index-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-previews-js": () => import("./../../../src/templates/previews.js" /* webpackChunkName: "component---src-templates-previews-js" */),
  "component---src-templates-savings-content-js": () => import("./../../../src/templates/savings-content.js" /* webpackChunkName: "component---src-templates-savings-content-js" */),
  "component---src-templates-savings-index-js": () => import("./../../../src/templates/savings-index.js" /* webpackChunkName: "component---src-templates-savings-index-js" */)
}

